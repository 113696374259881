import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteCompany,
  getCompany,
  getCompanySettings,
  getMyCompany,
  setConsult,
  setResponsibleEmployee,
  updateCompanySettings,
} from "../../api/company";
import { getConsultingEmployees, searchConsulting } from "../../api/consult";
import Button from "../../components/UI/Button/Button";
import Checkbox from "../../components/UI/Checkbox/Checkbox";
import Dropdown from "../../components/UI/Dropdown/Dropdown";
import ElasticSearch from "../../components/UI/ElasticSearch/ElasticSearch";
import Input from "../../components/UI/Input/Input";
import Spinner from "../../components/UI/Spinner/Spinner";
import Popup from "../../hoc/Popup/Popup";
import { ROUTE } from "../../shared/enums";
import { vatPeriodTypes } from "../../shared/liquidity";
import { nimyaAlert } from "../../shared/nimyaAlert";
import { convertToDropdown } from "../../shared/utility";
import {
  branches,
  paymentTermsDaysChoices,
  vatPeriods,
} from "../../shared/values";
import { setCompany } from "../../store/thunks/company";
import { IConsultSearchResult, IFortnoxScopes } from "../../types/api";
import {
  CompanySettingsNavigationItem,
  PaymentTermsDays,
} from "../../types/internal";
import { IReduxState } from "../../types/redux";
import "./CompanySettingsPopup.scss";

interface ICompanySettingsPopupProps {
  showPopup: boolean;
  onClose: () => void;
  companyId: string;
  onDelete: () => void;
  setNavigation?: CompanySettingsNavigationItem;
}

function CompanySettingsPopup(props: ICompanySettingsPopupProps) {
  const { companyId } = useSelector((state: IReduxState) => state.company);

  const [activeNavigation, setActiveNavigation] =
    useState<CompanySettingsNavigationItem>("Företagsinformation");
  const [isAddingAccountingFirm, setIsAddingAccountingFirm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [paymentsTermsDays, setPaymentsTermsDays] =
    useState<PaymentTermsDays>("0");
  const [vatPeriod, setVatPeriod] = useState<vatPeriodTypes>("MONTHLY");
  const [amountEmployees, setAmountEmployees] = useState(0);
  const [branch, setBranch] = useState("");
  const [advancedBudget, setAdvancedBudget] = useState(false);
  const [responsibleEmployeeId, setResponsibleEmployeeId] = useState("");
  const [accountingFirm, setAccountingFirm] = useState<{
    label: string;
    value: string;
  }>({
    label: "",
    value: "",
  });

  useEffect(() => {
    if (props.setNavigation) {
      setActiveNavigation(props.setNavigation);
    } else {
      setActiveNavigation("Företagsinformation");
    }
  }, [props.setNavigation]);

  const { data, isLoading, isSuccess, refetch } = useQuery({
    queryKey: ["getCompany", props.companyId],
    queryFn: async ({ queryKey: [, companyId] }) => {
      const [userRes, settingsRes, cRes] = await Promise.all([
        getMyCompany({
          companyId,
        }),
        getCompanySettings(companyId),
        getCompany(companyId),
      ]);

      return {
        companyData: cRes.data.payload,
        users: userRes.data.payload,
        settings: settingsRes.data.payload,
      };
    },
    enabled: props.showPopup,
  });

  const { data: accountingFirmEmployees } = useQuery({
    queryKey: [
      "accounting-firm-employees",
      data?.companyData.company.consultingAgency || "",
      props.companyId,
    ],
    queryFn: async ({ queryKey: [, consultId, companyId] }) => {
      const res = await getConsultingEmployees({ consultId, companyId });

      return res.data.payload.employees;
    },
    enabled: !!data?.companyData.company.consultingAgency,
  });

  useEffect(() => {
    if (isSuccess) {
      const { settings, companyData } = data;
      setPaymentsTermsDays(settings.paymentsTermsDays as PaymentTermsDays);
      setVatPeriod(settings.vatPeriod);
      setAmountEmployees(settings.amountEmployees);
      setBranch(settings.branch);
      setAccountingFirm({
        label: companyData.company.consultingAgencyInfo?.name || "",
        value: companyData.company.consultingAgency || "",
      });
      setAdvancedBudget(settings.advancedBudget);
      setResponsibleEmployeeId("");
    }
  }, [isSuccess, data]);

  async function handleAccountingFirmSearch(searchString: string) {
    const res = await searchConsulting(searchString);
    return res.data.payload.hits;
  }

  async function addAccountingFirmHanlder(consultId: string) {
    setIsAddingAccountingFirm(true);

    try {
      await setConsult({
        companyId: props.companyId,
        consultId: consultId,
      });
      toast.success("Redovisningsbyrå tillagd");
      setIsAddingAccountingFirm(false);
      refetch();
    } catch (error) {
      toast.error("Något gick fel med att lägga till redovisningsbyrå");
      setIsAddingAccountingFirm(false);
    }
  }

  async function saveSettingsHandler() {
    setIsSaving(true);

    try {
      await updateCompanySettings({
        companyId: props.companyId,
        data: {
          paymentsTermsDays,
          vatPeriod,
          amountEmployees,
          branch,
          advancedBudget,
        },
      });
      toast.success("Inställningar sparade");

      //For updating the company settings in the redux store
      if (props.companyId === companyId) {
        setCompany(companyId);
      }

      refetch();

      setIsSaving(false);
    } catch (error) {
      toast.error("Något gick fel");

      setIsSaving(false);
    }
  }

  async function saveAccountingFirmSettings() {
    setIsSaving(true);

    if (!responsibleEmployeeId) {
      toast.error("Du måste välja en redovisningskonsult");
      return;
    }

    try {
      await setResponsibleEmployee({
        companyId: props.companyId,
        userId: responsibleEmployeeId,
      });

      toast.success("Redovisningskonsult tilldelad");
      setIsSaving(false);
    } catch (error) {
      toast.error("Något gick fel med att tilldela redovisningskonsult");
      setIsSaving(false);
    }
  }

  async function removeCompanyHandler() {
    const confirmText = `Är du säker på att du vill ta bort ${
      data?.companyData.company.name || "företaget"
    }? Denna åtgärd går inte att ångra.`;
    if (
      !(await nimyaAlert({
        title: "Ta bort företag",
        message: confirmText,
      }))
    )
      return;

    const promptText = `Ange 'Radera' för att ta bort ${
      data?.companyData.company.name || "företaget"
    }`;
    if (
      !(await nimyaAlert({
        title: "Ta bort företag",
        message: promptText,
        conifrmText: "Radera",
      }))
    )
      return;
    setIsDeleting(true);

    try {
      await deleteCompany(props.companyId);
      setIsDeleting(false);
      toast.success(
        `${data?.companyData.company.name || "företaget"} raderades`
      );
      props.onDelete();
    } catch (error) {
      toast.error("Något gick fel");
      setIsDeleting(false);
    }
  }

  function getScopeName(key: keyof IFortnoxScopes) {
    switch (key) {
      case "companyInformation":
        return "Företagsinformation";
      case "bookKeeping":
        return "Bokföring";
      case "invoice":
        return "Faktura";
      case "payment":
        return "Betalning";
      case "supplierInvoice":
        return "Leverantörsfaktura";
      case "customer":
        return "Kund";
      case "salary":
        return "Lön";
      case "costCenter":
        return "Kostnadsställe";
      case "article":
        return "Artikel";
      case "project":
        return "Projekt";
    }
  }

  const navigationItems: CompanySettingsNavigationItem[] = [
    "Företagsinformation",
    "Redovisningsbyrå",
    "Fortnox moduler",
    "Danger Zone",
  ];

  return (
    <Popup
      showPopup={props.showPopup}
      onClose={props.onClose}
      leftTopElement={
        <div className="company-settings-title">
          <p className="company-name text-m-m">
            Inställningar {data?.companyData.company.name}
          </p>
          <p className="company-number text-s-m">
            Org. nummer: {data?.companyData.company.orgNr}
          </p>
        </div>
      }
      style={{ overflow: "hidden" }}
    >
      {isLoading || !isSuccess ? (
        <Spinner padding="10px" />
      ) : (
        <div className="company-settings-popup">
          <div className="navigation">
            {navigationItems
              .filter((ni) =>
                data.companyData.company.isVisma
                  ? ni !== "Fortnox moduler"
                  : true
              )
              .map((ni) => (
                <button
                  key={ni}
                  className={`nav-item text-m-r${
                    activeNavigation === ni ? " active" : ""
                  }`}
                  onClick={() => setActiveNavigation(ni)}
                >
                  {ni}
                </button>
              ))}
          </div>
          <div
            className={`content${
              activeNavigation === "Företagsinformation" ? " active" : ""
            }`}
          >
            <div className="company-settings-row">
              <span className="text-s-r">Antal anställda</span>
              <Input
                type="number"
                placeholder="Antal anställda"
                onChange={(v) => setAmountEmployees(+v)}
                value={`${amountEmployees}`}
                width="200px"
                short
              />
            </div>
            <div className="company-settings-row">
              <span className="text-s-r">Bransch</span>
              <Dropdown
                title="Branch"
                onSelect={setBranch}
                value={branch}
                options={convertToDropdown({
                  list: branches,
                  valueKey: "code",
                  labelKey: "name",
                })}
                width="200px"
                isSearchable
                short
              />
            </div>
            <div className="company-settings-row">
              <span className="text-s-r">
                Antal betalningsvillkorliga dagar
              </span>
              <Dropdown
                title="Betaldagar"
                onSelect={(v) => setPaymentsTermsDays(v as PaymentTermsDays)}
                value={paymentsTermsDays}
                options={paymentTermsDaysChoices}
                width="200px"
                short
              />
            </div>
            <div className="company-settings-row">
              <span className="text-s-r">Momsperiod</span>
              <Dropdown
                title="Momsperiod"
                onSelect={(v) => setVatPeriod(v as vatPeriodTypes)}
                value={vatPeriod}
                options={vatPeriods}
                width="200px"
                short
              />
            </div>
            <div className="company-settings-row">
              <span className="text-s-r">
                Redovisas budget och resultat på kontonivå?
              </span>
              <div className="checkboxes">
                <Checkbox
                  label="Ja"
                  checked={advancedBudget}
                  theme="dark"
                  onClick={() => setAdvancedBudget(true)}
                  short
                />
                <Checkbox
                  label="Nej"
                  checked={!advancedBudget}
                  theme="dark"
                  onClick={() => setAdvancedBudget(false)}
                  short
                />
              </div>
            </div>
            <div className="company-settings-row">
              <span className="text-s-r">Visa verifikationer</span>

              <Link
                to={ROUTE.Vouchers}
                onClick={() => setCompany(props.companyId)}
              >
                <Button
                  label="Till verifikationer"
                  color="black"
                  width="200px"
                  short
                />
              </Link>
            </div>
            <div className="save-settings">
              <Button
                label="Spara"
                color="black"
                onClick={saveSettingsHandler}
                width="200px"
                isLoading={isSaving}
                short
              />
            </div>
          </div>
          <div
            className={`content${
              activeNavigation === "Redovisningsbyrå" ? " active" : ""
            }`}
          >
            <div className="company-settings-row">
              <span className="text-s-r">
                {data?.companyData.company.consultingAgencyConfirmed === false
                  ? `Inväntar bekräftelse från ${
                      data?.companyData.company.consultingAgencyInfo?.name ||
                      "redovisningsbyrån"
                    }`
                  : "Företagets redovisningsbyrå"}
              </span>
              <div className="accounting-firm">
                <ElasticSearch<IConsultSearchResult[]>
                  title="Välj konsultbolag"
                  handleSearch={handleAccountingFirmSearch}
                  value={accountingFirm}
                  onSelect={setAccountingFirm}
                  convertResult={(result) =>
                    convertToDropdown({
                      list: result,
                      labelKey: "name",
                      valueKey: "id",
                    })
                  }
                  width="200px"
                  short
                />
                <Button
                  label={
                    data?.companyData.company.consultingAgencyInfo?.name
                      ? "Uppdatera"
                      : "Lägg till"
                  }
                  color="black"
                  onClick={() => addAccountingFirmHanlder(accountingFirm.value)}
                  width="100px"
                  isLoading={isAddingAccountingFirm}
                  short
                />
              </div>
            </div>
            <div className="company-settings-row">
              <span className="text-s-r">Redovisningskonsult</span>
              <Dropdown
                title="Konsult"
                options={accountingFirmEmployees?.map((emp) => ({
                  label: `${emp.user.first_name || emp.user.email} ${
                    emp.user.last_name || ""
                  }`,
                  value: emp.user._id,
                }))}
                onSelect={setResponsibleEmployeeId}
                value={responsibleEmployeeId}
                width="310px"
                short
              />
            </div>
            <div className="save-settings">
              <Button
                label="Spara"
                color="black"
                onClick={saveAccountingFirmSettings}
                width="200px"
                isLoading={isSaving}
                short
              />
            </div>
          </div>
          <div
            className={`content${
              activeNavigation === "Fortnox moduler" ? " active" : ""
            }`}
          >
            {Object.keys(data.settings.fortnoxScopes || {}).map((key) => (
              <div className="company-settings-row" key={key}>
                <span className="text-s-r">
                  {getScopeName(key as keyof IFortnoxScopes)}
                </span>
                <span className="text-s-r">
                  {data.settings.fortnoxScopes?.[key as keyof IFortnoxScopes]
                    ? "Aktiv"
                    : "Inaktiv"}
                </span>
              </div>
            ))}
          </div>
          <div
            className={`content${
              activeNavigation === "Danger Zone" ? " active" : ""
            }`}
          >
            <div className="company-settings-row">
              <span className="text-s-r">Ta bort företag</span>
              <Button
                label="Radera"
                color="red"
                onClick={removeCompanyHandler}
                width="200px"
                isLoading={isDeleting}
                short
              />
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
}

export default CompanySettingsPopup;
