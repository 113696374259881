import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getConsultingEmployees,
  inviteToConsulting,
  removeFromConsulting,
  updateUserRole,
} from "../../api/consult";
import { getMe } from "../../api/user";
import Dot from "../../components/Dot/Dot";
import DotsModal from "../../components/DotsModal/DotsModal";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import { nimyaAlert } from "../../shared/nimyaAlert";
import { IReduxState } from "../../types/redux";
import "./AccountingFirmEmployees.scss";

interface IAccountingFirmEmployeesProps {}

function AccountingFirmEmployees(props: IAccountingFirmEmployeesProps) {
  const { agency } = useSelector((state: IReduxState) => state.consult);

  const [addEmail, setAddEmail] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [adminEmails, setAdminEmails] = useState<string[]>([]);
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    getMe().then((res) => setUserEmail(res.data.payload.email));
  }, []);

  const { data, isSuccess, refetch } = useQuery({
    queryKey: ["accounting-firm-employees", agency!._id],
    queryFn: async ({ queryKey: [, consultId] }) => {
      const res = await getConsultingEmployees({ consultId });

      setAdminEmails(
        res.data.payload.employees
          .filter((emp) => emp.role === "admin")
          .map((emp) => emp.user.email)
      );

      return res.data.payload.employees;
    },
  });

  async function adminClickHandler(
    consultId: string,
    userId: string,
    shouldPromote: boolean
  ) {
    if (!adminEmails.includes(userEmail)) {
      toast.error("Du måste vara admin för att kunna ändra adminroll");
      return;
    }

    const confirmText = shouldPromote
      ? "Är du säker på att du vill tilldela adminroll för denna användare?"
      : "Är du säker på att du vill ta bort adminroll för denna användare?";

    if (
      !(await nimyaAlert({
        title: "Adminroll",
        message: confirmText,
      }))
    )
      return;

    try {
      await updateUserRole({
        consultId,
        userId,
        role: shouldPromote ? "admin" : "user",
      });

      const sucessText = shouldPromote
        ? "Användare uppgraderas admin"
        : "Användare degraderad till användare";
      toast.success(sucessText);
    } catch (error) {
      toast.error("Något gick fel");
    }

    refetch();
  }

  async function removeUserHandler(consultId: string, userId: string) {
    if (
      !(await nimyaAlert({
        title: "Ta bort användare",
        message: "Är du säker att du vill ta bort denna användare?",
      }))
    )
      return;

    try {
      await removeFromConsulting({
        consultId,
        userId,
      });
      toast.success("Användare borttagen");
    } catch (error) {
      toast.error("Något gick fel");
    }

    refetch();
  }

  async function inviteUserHandler(email: string) {
    setIsAdding(true);
    try {
      await inviteToConsulting({
        consultId: agency!._id,
        email,
      });
      toast.success("Inbjudan skickad");
      setAddEmail("");
      setIsAdding(false);
    } catch (error) {
      toast.error("Något gick fel");
      setIsAdding(false);
    }

    refetch();
  }

  const isUserAdmin = adminEmails.includes(userEmail);

  return (
    <div className="accounting-firm-employees">
      <p className="title text-m-m">{agency!.name}</p>
      {isSuccess && (
        <table>
          <thead>
            <tr className="text-m-m">
              <th>Namn på konsult</th>
              <th>E-post</th>
              <th>Grupper</th>
              {isUserAdmin && <th>Administratörer</th>}
              <th />
            </tr>
          </thead>
          <tbody>
            {data.map((emp) => (
              <tr key={emp.userId} className="text-s-r">
                <td>
                  {emp.user.first_name || ""} {emp.user.last_name || ""}
                </td>
                <td>{emp.user.email}</td>
                <td>
                  {`${emp.groups
                    .filter((_, i) => i < 2)
                    .map((g) => g.group.name)
                    .join(", ")}${
                    emp.groups.length > 2 ? `, +${emp.groups.length - 2}` : ""
                  }`}
                </td>
                <td>
                  {(isUserAdmin || emp.role !== "admin") && (
                    <Dot
                      active={emp.role === "admin"}
                      label="AD"
                      onClick={() =>
                        adminClickHandler(
                          agency!._id,
                          emp.userId,
                          emp.role !== "admin"
                        )
                      }
                    />
                  )}
                </td>
                <td>
                  {isUserAdmin && (
                    <div className="settings-dots">
                      <DotsModal
                        title={"Administrera"}
                        content={[
                          {
                            title: "Ta bort användare",
                            onClick: () =>
                              removeUserHandler(agency!._id, emp.userId),
                          },
                        ]}
                      />
                    </div>
                  )}
                </td>
              </tr>
            ))}
            <tr className="form-row">
              <td>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    inviteUserHandler(addEmail);
                  }}
                >
                  <Input
                    type="email"
                    value={addEmail}
                    onChange={setAddEmail}
                    placeholder="Ange e-post att bjuda in"
                    short
                    width="100%"
                  />
                </form>
              </td>
              <td colSpan={100}>
                <Button
                  label="Skicka"
                  color="black"
                  short
                  onClick={() => inviteUserHandler(addEmail)}
                  isLoading={isAdding}
                />
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
}

export default AccountingFirmEmployees;
