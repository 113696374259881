import { getUnixTime, subMonths } from 'date-fns';

import { getCompany, getCompanySettings, getFinancialYears } from '../../api/company';
import { LOCAL_STORAGE_KEY } from '../../shared/enums';
import { IKeyString } from '../../types/internal';
import store from '../index';
import { setCompanyData, setCompanyStart } from '../slices/company';

const { dispatch } = store;

export async function setCompany(companyId: string) {
  dispatch(setCompanyStart());
  localStorage.setItem(LOCAL_STORAGE_KEY.CompanyId, companyId);

  const [companyRes, settingsRes] = await Promise.all([
    getCompany(companyId),
    getCompanySettings(companyId),
  ]);

  const company = companyRes.data.payload;
  const settings = settingsRes.data.payload;

  const licenses: IKeyString<boolean> = {};
  Object.keys(company?.license?.licenses || {}).forEach((key) => {
    licenses[key] = company?.license.licenses?.[key].active || false;
  });

  const today = new Date();

  const lastMonthUnix = getUnixTime(subMonths(today, 1));

  const fYearsRes = await getFinancialYears({ companyId });
  const sortedFinancialYears = fYearsRes.data.payload.sort(
    (a, b) => b.fromDate - a.fromDate
  );

  const latestFinancialYear = sortedFinancialYears.filter(
    (fy) => fy.fromDate < lastMonthUnix
  )[0];

  // TODO kinda hacky, should probably implement a retry for the financial years
  let startUnix = getUnixTime(new Date(new Date().getFullYear(), 0, 1));
  let endUnix = getUnixTime(new Date(new Date().getFullYear(), 11, 31));
  let financialYear: string | number = "";

  if (latestFinancialYear) {
    startUnix = latestFinancialYear.fromDate;
    endUnix = latestFinancialYear.toDate;
    financialYear = latestFinancialYear.financialYear;
  }

  const isRedoSetup = company?.company.isVisma
    ? company.company.isVismaRedo
    : company?.company.isFortnox
    ? company.company.isFortnoxRedo
    : false;

  dispatch(
    setCompanyData({
      companyId,
      settings,
      licenses,
      companyData: {
        currentUserPermission: company.license.role,
        isConcern: !!company?.company.isConcern,
        isRedoSetup: !!isRedoSetup,
        hasIndependantAccess: !!company?.license.hasIndependantAccess,
        dataSource: company?.company.isVisma
          ? "visma"
          : company?.company.isFortnox
          ? "fortnox"
          : "",
      },
      financialYear: {
        identifier:
          typeof financialYear === "number"
            ? financialYear.toString()
            : financialYear,
        from: startUnix,
        to: endUnix,
      },
      financialYears: sortedFinancialYears,
    })
  );
}
