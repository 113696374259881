import "./Balance.scss";

import { eachMonthOfInterval, fromUnixTime, lightFormat } from "date-fns";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import { getBalanceReport, getPeriodBalances } from "../../api/report";
import BalanceReport from "../../components/BalanceReport/BalanceReport";
import LineChart from "../../components/Charts/LineChart/LineChart";
import ExplanationBalance from "../../components/ExplanationBalance/ExplanationBalance";
import Spinner from "../../components/UI/Spinner/Spinner";
import ChartWrapper from "../../hoc/ChartWrapper/ChartWrapper";
import { useReportValues } from "../../shared/hooks/useReportValues";
import {
  getPaymentAbility,
  getResultFromPeriodBalances,
} from "../../shared/reportUtility";
import { months } from "../../shared/values";
import { IBalanceReport, IPeriodBalances } from "../../types/api";
import { IKeyString } from "../../types/internal";
import { IReduxState } from "../../types/redux";

interface IBalanceProps {}

function Balance(props: IBalanceProps) {
  const { companyId } = useSelector((state: IReduxState) => state.company);

  const { from, to, setControlsComponent } = useReportValues();

  useEffect(() => {
    setControlsComponent(undefined);
  }, [setControlsComponent]);

  const { data, isLoading, isSuccess } = useQuery<
    { balance: IBalanceReport; periodBalances: IPeriodBalances },
    unknown,
    { balance: IBalanceReport; periodBalances: IPeriodBalances },
    ["balance", number, number, string]
  >({
    queryKey: ["balance", from, to, companyId],
    queryFn: async ({ queryKey: [, from, to, companyId] }) => {
      const [balanceRes, pbRes] = await Promise.all([
        getBalanceReport({
          from,
          to,
          companyId,
        }),
        getPeriodBalances({ from, to, companyId }),
      ]);

      return {
        balance: balanceRes.data.payload,
        periodBalances: pbRes.data.payload,
      };
    },
  });

  function getLinechartData(
    balance: IBalanceReport,
    pbs: IPeriodBalances,
    monthsArray: string[]
  ) {
    const paymentAbility = getPaymentAbility(pbs, monthsArray);

    const result: IKeyString<number[]> = {
      "Kassa och bank": [balance.Tillgångar["Kassa och bank"].IB || 0],
      "Eget kapital": [balance["Eget kapital"]["Eget kapital"].IB || 0],
      Betalningsförmåga: [
        paymentAbility["Korta fodringar"]["IB"] +
          paymentAbility["Kassa och bank"]["IB"] -
          paymentAbility["Korta skulder"]["IB"],
      ],
    };

    const calcResult = getResultFromPeriodBalances({ pbs, monthsArray });

    monthsArray.forEach((month) => {
      const cashAndBank =
        (balance.Tillgångar["Kassa och bank"][month] || 0) +
        result["Kassa och bank"][result["Kassa och bank"].length - 1];

      result["Kassa och bank"].push(cashAndBank);

      const ownCapitalPlusResultMinusTax =
        (balance["Eget kapital"]["Eget kapital"][month] || 0) +
        calcResult[month] * 0.794;

      result["Eget kapital"].push(
        ownCapitalPlusResultMinusTax +
          result["Eget kapital"][result["Eget kapital"].length - 1]
      );

      const pa =
        paymentAbility["Korta fodringar"][month] +
        paymentAbility["Kassa och bank"][month] -
        paymentAbility["Korta skulder"][month];

      result["Betalningsförmåga"].push(
        pa + result["Betalningsförmåga"][result["Betalningsförmåga"].length - 1]
      );
    });

    return result;
  }

  const monthsArray = eachMonthOfInterval({
    start: fromUnixTime(from),
    end: fromUnixTime(to),
  }).map((d) => lightFormat(d, "yyyy-M"));

  let lineChartData: IKeyString<number[]> = {};

  if (isSuccess) {
    lineChartData = getLinechartData(
      data.balance,
      data.periodBalances,
      monthsArray
    );
  }

  return (
    <div className="balance">
      {isLoading && <Spinner />}
      {/* {isSuccess && (
        <ChartWrapper
          leftLegend={[
            {
              label: "Kassa och bank",
              color: "#FFB800",
              textColor: "#fff",
            },
            {
              label: "Eget kapital + Resultat - Skatt",
              color: "#FC3EC4",
              textColor: "#fff",
            },
            {
              label: "Betalningsförmåga",
              color: "#00B448",
              textColor: "#fff",
            },
          ]}
        >
          <LineChart
            labels={[
              `IB ${lightFormat(fromUnixTime(from), "yyyy-M-d")}`,
              ...monthsArray.map((mKey) =>
                months[+mKey.split("-")[1] - 1].label.slice(0, 3)
              ),
            ]}
            datasets={[
              {
                label: "Kassa och bank",
                data: lineChartData["Kassa och bank"],
                borderColor: "#FFB800",
                backgroundColor: "transparent",
              },
              {
                label: "Eget kapital",
                data: lineChartData["Eget kapital"],
                borderColor: "#FC3EC4",
                backgroundColor: "transparent",
              },
              {
                label: "Betalningsförmåga",
                data: lineChartData["Betalningsförmåga"],
                borderColor: "#00B448",
                backgroundColor: "transparent",
              },
            ]}
          />
        </ChartWrapper>
      )} */}
      {/* {isSuccess && (
        <ExplanationBalance
          periodBalances={data.periodBalances}
          balanceReport={data.balance}
          from={from}
          to={to}
        />
      )} */}
      {isSuccess && <BalanceReport report={data.balance} from={from} to={to} />}
    </div>
  );
}

export default Balance;
