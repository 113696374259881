export enum LOCAL_STORAGE_KEY {
  Token = "nimya-token",
  RefreshToken = "nimya-refresh",
  CompanyId = "nimya-companyId",
  ReportDrafts = "nimya-report-drafts",
  BudgetDeltas = "nimya-budget-deltas",
  AdminToken = "nimya-admin-token",
  AdminRefreshToken = "nimya-admin-refresh",
  ExtendedFilter = "nimya-extended-filter",
}

export enum ROUTE {
  Index = "/",

  /*Auth */
  Login = "/",
  SignUp = "/skapa-konto",
  Verify = "/verifiera",
  OAuthCallback = "/oauth/callback",
  Setup = "/setup",
  ForgotPassword = "/forgot-password",

  /*Onboarding */
  NoCompanies = "/forsta-foretaget",

  /*Reports */
  Reports = "/rapporter",
  Result = "/rapporter/resultat",
  Balance = "/rapporter/balans",
  CashFlow = "/rapporter/kassaflode",
  // KeyValues = "/rapporter/nyckeltal",

  /*KeyValue */
  KeyValues = "/nyckeltal",

  /*Future */
  Future = "/framtid",
  Budget = "/framtid/budget",
  Forecast = "/framtid/prognos",

  /*Counseling */
  Counseling = "/radgivning",
  Alerts = "/radgivning/notiser",
  AlertSettings = "/radgivning/notiser-installningar",
  Insights = "/radgivning/insikter",

  /*Settings */
  Settings = "/installningar",
  CompanySettings = "/installningar/foretag",
  ConcernSettings = "/installningar/koncern",
  GroupSettings = "/installningar/grupp",

  /*Accounting firm */
  AccountingFirmSettings = "/installningar/redovisningsbyra",
  AccountingFirmEmployees = "/installningar/redovisningsbyra/medarbetare",
  AccountingFirmCustomers = "/installningar/redovisningsbyra/kunder",
  AccountingFirmGroups = "/installningar/redovisningsbyra/grupper",
  AccountingFirmProfile = "/installningar/redovisningsbyra/profil",

  /*Vouchers */
  Vouchers = "/verifikationer",

  /*Maintenance */
  DownForMaintenance = "/down-for-maintenance",

  /*Admin */
  AdminLogin = "/admin-login",
  Admin = "/admin",
  AdminUsers = "/admin/anvandare",
  AdminAccountingFirms = "/admin/redovisningsbyra",
}

export enum ROUTE_LABEL {
  Index = "Översikt",

  /*Auth */
  Login = "Logga in",
  SignUp = "Skapa konto",
  Verify = "Verifiera",
  OAuthCallback = "OAuth Callback",
  Setup = "Setup",
  ForgotPassword = "Glömt lösenord",

  /*Onboarding */
  NoCompanies = "Första företaget",

  /*Reports */
  Reports = "Rapporter",
  Result = "Resultat",
  Balance = "Balans",
  CashFlow = "Kassaflöde",
  KeyValues = "Nyckeltal",

  /*Future */
  Future = "Framtid",
  Budget = "Budget",
  Forecast = "Prognos",

  /*Counseling */
  Counseling = "Rådgivning",
  Alerts = "Notiser",
  AlertSettings = "Notiser Inställningar",
  Insights = "Insikter",

  /*Settings */
  Settings = "Inställningar",
  CompanySettings = "Företag",
  ConcernSettings = "Koncern",
  GroupSettings = "Grupp",

  /*Accounting firm */
  AccountingFirmSettings = "Redovisningsbyrå",
  AccountingFirmEmployees = "Medarbetare",
  AccountingFirmCustomers = "Kunder",
  AccountingFirmGroups = "Grupper",
  AccountingFirmProfile = "Företagsprofil",

  /*Vouchers */
  Vouchers = "Verifikationer",

  /*Admin */
  AdminLogin = "Admin Logga in",
  Admin = "Admin",
  AdminUsers = "Användare",
  AdminAccountingFirms = "Redovisningsbyrå",
}

export enum API_MAX_PER_PAGE {
  Messages = 10,
  Overview = 7,
  Vouchers = 100,
  AdminUsers = 100,
  ConsultQueue = 100,
  AdminConsults = 100,
}

export enum API_MESSAGE {
  NoNotificationsFound = "No comments found",
  UserAlreadyExists = "User already exists",
  UserAlreadyInCompany = "User is already in company",
  NoCompaniesFoundOverview = "No companies found",
  NoGroups = "No groups found",
  NoCompaniesInGroup = "No companies in group",
  UserNotFound = "User not found",
  CompanyAlreadyInGroup = "Company already in group",
  GroupNameTaken = "Group with this name already exists",
  OwnerCantLeaveGroup = "Owner cant leave group",
  NoticeToDateInFuture = "to date cannot be in the future",
  UserNotAdmin = "User is not admin",
  UserVerificationAlreadySent = "User verification is already sent. You can try again after 48 hours of the original request",
  AuthKeyExpired = "Key has expired",
  AuthKeyInvalid = "Invalid key",
  InvalidVariableName = "name may only contain alphanumeric characters, nordic (ÖÄÅ) or spaces)",
  UserCannotConfirmConsultingCompanies = "User is not allowed to confirm consulting companies",
  FailedToValidateToken = "Failed validate token",
  DuplicateVariableName = "a variable with this name already exists",
}

export enum ECONOMIC_TERM {
  Income = "Intäkter",
  Interval = "Intervall",
  Sum = "Summa",
}

export enum ALERT_VALUE {
  DescriptionValue = "$value",
  BreakValueCalcSymbol = "@",
  BreakValueTitleSymbol = "X",
  AlertFalseResult = "alert false",
}

export enum VARIABLE_VALUE {
  GlobalPrefix = "GLOBAL_",
}

export enum SESSION_STORAGE_KEY {
  ReportData = "report-data",
}

export enum QUERY_PARAM {
  CompanyId = "companyId",
  VariableId = "variableId",
  AccessToken = "access_token",
  RefreshToken = "refresh_token",
  AuthKey = "key",
  CompanyError = "error",
  CompanyErrorMessage = "message",
  Graph = "graph",
  From = "from",
  To = "to",
  MessageId = "commentId",
}

export enum VARIABLE_CONTEXT_COLOR {
  Income = "#DEEAFF",
  Expense = "#FDF8FF",
  Result = "#E8F8F9",
  Default = "#EFEFFF",
}

export enum VARIABLE_CONTEXT_TEXT_COLOR {
  Income = "#5B93FF",
  Expense = "#EF5FFC",
  Result = "#00B448",
  Default = "#374263",
}

export enum ERROR_TEXT {
  RedoSetup = "Anslutning till externa datakällan för detta företag har tappats. Klicka här för att göra om kopplingen till företaget och återuppta datainläsningen",
  RedoSetupClickHere = "Klicka här för att lägga om kopplingen till företaget och återuppta datainläsningen",
}
