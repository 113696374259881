import { AxiosError } from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getOauthGoogleUrl,
  loginEmailAndPassword,
  resetPassword,
} from "../../api/auth";
import Button from "../../components/UI/Button/Button";
import Input from "../../components/UI/Input/Input";
import OnbordingLayout from "../../hoc/OnbordingLayout/OnbordingLayout";
import { ROUTE } from "../../shared/enums";
import { login } from "../../store/thunks/auth";
import { IReduxState } from "../../types/redux";
import "./Login.scss";

interface ILoginProps {}

function Login(props: ILoginProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [hasForgottenPassword, setHasForgottenPassword] = useState(false);
  const [isForgotLoading, setIsForgotLoading] = useState(false);
  const isLoading = useSelector((state: IReduxState) => state.auth.loading);

  function oAuthGoogleLoginHandler() {
    window.location.href = getOauthGoogleUrl();
  }

  async function loginHandler() {
    if (!email || !password) {
      toast.error("Du måste ange e-postadress och lösenord för att logga in.");
      return;
    }

    try {
      const res = await loginEmailAndPassword({ email, password });

      login({
        accessToken: res.data.payload.access_token,
        refreshToken: res.data.payload.refresh_token,
      });
    } catch (e) {
      const error = e as AxiosError;

      const errorText =
        error.response?.status === 401
          ? "Felaktiga inloggningsuppgifter"
          : "Något gick fel";
      toast.error(errorText);
    }
  }

  async function forgotPasswordHandler(email: string) {
    if (!email) {
      toast.error("Du måste ange e-postadress för att få ett nytt lösenord.");
      return;
    }
    setIsForgotLoading(true);
    try {
      await resetPassword(email);
    } catch (error) {
      toast.error("Något gick fel");
      setIsForgotLoading(false);
      return;
    }
    setIsForgotLoading(false);
    toast.success("Ett nytt lösenord har skickats till din e-postadress.");
  }

  return (
    <OnbordingLayout>
      <p className="login-content-title space-grotesk">Logga in</p>
      <div className="login-content-oauth-buttons">
        <Button
          color="white"
          label="Google"
          icon="google"
          width="100%"
          isLoading={isLoading}
          onClick={oAuthGoogleLoginHandler}
        />
      </div>
      <p className="login-content-divider">Eller</p>
      <form
        className="login-content-form"
        onSubmit={(e) => {
          e.preventDefault();
          loginHandler();
        }}
      >
        <Input
          type="email"
          placeholder="E-postadress"
          label="E-postadress"
          value={email}
          onChange={(v) => {
            setEmail(v);
            setForgotPasswordEmail(v);
          }}
        />
        <Input
          type="password"
          placeholder="Lösenord"
          label="Lösenord"
          value={password}
          onChange={setPassword}
        />
        <Button color="black" label="Logga in" isLoading={isLoading} />
      </form>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          forgotPasswordHandler(forgotPasswordEmail);
        }}
        className="login-content-forgot-password"
      >
        <p onClick={() => setHasForgottenPassword((state) => !state)}>
          {hasForgottenPassword ? "Avbryt" : "Glömt lösenordet?"}
        </p>
        {hasForgottenPassword && (
          <>
            <Input
              type="email"
              placeholder="E-postadress"
              label="E-postadress"
              value={forgotPasswordEmail}
              onChange={setForgotPasswordEmail}
            />
            <Button
              color="black"
              label="Återställ"
              isLoading={isForgotLoading}
            />
          </>
        )}
      </form>

      {/* <p className="login-content-sign-up">
        Har du inget konto?{" "}
        <NavLink to={ROUTE.SignUp} className="sign-up-link">
          Registrera dig
        </NavLink>
      </p> */}
    </OnbordingLayout>
  );
}

export default Login;
