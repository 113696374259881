import './AccountingFirmCustomers.scss';

import { fromUnixTime, lightFormat } from 'date-fns';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { confirmConsultingClient, getConsultingClients } from '../../api/consult';
import { getLicenses } from '../../api/license';
import LicenseDots from '../../components/LicenseDots/LicenseDots';
import Checkbox from '../../components/UI/Checkbox/Checkbox';
import UnreadIndicator from '../../components/UI/UnreadIndicator/UnreadIndicator';
import { API_MESSAGE } from '../../shared/enums';
import { IReduxState } from '../../types/redux';

interface IAccountingFirmCustomersProps {}

function AccountingFirmCustomers(props: IAccountingFirmCustomersProps) {
  const { agency } = useSelector((state: IReduxState) => state.consult);

  const [customerMode, setCustomerMode] = useState<"active" | "inactive">(
    "active"
  );

  const { data, isSuccess, refetch } = useQuery({
    queryKey: ["accounting-firm-customers", agency!._id],
    queryFn: async ({ queryKey: [, agencyId] }) => {
      const res = await getConsultingClients(agencyId);
      return res.data.payload;
    },
  });

  const { data: licenses, isSuccess: isLicensesSuccess } = useQuery({
    queryKey: "licenses",
    queryFn: async () => {
      const res = await getLicenses();
      return res.data.payload;
    },
  });

  async function confirmCustomerHandler(companyId: string, confirm: boolean) {
    try {
      await confirmConsultingClient({
        companyId,
        confirm,
        consultId: agency!._id,
      });

      if (confirm) {
        toast.success("Företag accepterat");
      } else {
        toast.success("Företag nekat");
      }
      refetch();
    } catch (error: any) {
      if (
        error.response.data.message ===
        API_MESSAGE.UserCannotConfirmConsultingCompanies
      ) {
        toast.error("Endast admins kan bekräfta eller neka företag");
        return;
      }
      toast.error("Något gick fel");
    }
  }

  return (
    <div className="accounting-firm-customers">
      <div className="top">
        <Checkbox
          label="Aktiva företag"
          checked={customerMode === "active"}
          theme="dark"
          onClick={() => setCustomerMode("active")}
          short
        />
        <div className="customer-mode-checkbox">
          <Checkbox
            label="Väntande företag"
            checked={customerMode === "inactive"}
            theme="dark"
            onClick={() => setCustomerMode("inactive")}
            short
          />
          <UnreadIndicator
            amount={data?.inactiveCompanies.length || 0}
            position={{
              top: -10,
              right: -15,
            }}
            title={`${data?.inactiveCompanies.length} väntande företag`}
          />
        </div>
      </div>
      {isSuccess &&
        (customerMode === "active" ? (
          data.activeCompanies.length ? (
            <table className="active-customers">
              <thead className="text-m-m">
                <tr>
                  <th>Namn</th>
                  <th>Admin e-post</th>
                  <th>Licenser</th>
                  <th>Tillagt</th>
                </tr>
              </thead>
              <tbody>
                {data.activeCompanies.map((c) => (
                  <tr key={c._id} className="text-s-r">
                    <td>{c.name}</td>
                    <td>{c.responsibleEmployee?.email || ""}</td>
                    <td>
                      <LicenseDots
                        licenses={isLicensesSuccess ? licenses : []}
                        activeLicenses={c.permissions
                          .filter((p) => p.active)
                          .map((p) => ({
                            licenseId: p.licenseId || "",
                            active: p.active,
                          }))}
                        onClick={() => {}}
                        lockedNames={
                          isLicensesSuccess ? licenses.map((l) => l.name) : []
                        }
                      />
                    </td>
                    <td>
                      {lightFormat(
                        fromUnixTime(c.consultConfirmDate),
                        "yyyy-MM-dd"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="no-waiting-customers">
              Inga företag i redovisningsbyrån
            </p>
          )
        ) : data.inactiveCompanies.length ? (
          <table className="inactive-customers">
            <thead className="text-m-m">
              <tr>
                <th>Namn</th>
                <th>Admin e-post</th>
                <th>Acceptera/neka företag</th>
              </tr>
            </thead>
            <tbody>
              {data.inactiveCompanies.map((c) => (
                <tr key={c._id} className="text-s-r">
                  <td>{c.name}</td>
                  <td></td>
                  <td>
                    <button
                      className="confirm-button waves-effect"
                      onClick={() => confirmCustomerHandler(c._id, true)}
                    >
                      <i className="fa-solid fa-check" />
                    </button>
                    <button
                      className="deny-button waves-effect"
                      onClick={() => confirmCustomerHandler(c._id, false)}
                    >
                      <i className="fa-solid fa-x" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="no-waiting-customers">Inga väntande företag</p>
        ))}
    </div>
  );
}

export default AccountingFirmCustomers;
